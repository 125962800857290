<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <b-card-body>
        <form id="filter" @submit.prevent="filterTable">
            <div class="row d-flex">
                <b-col v-for="(input, index) in filter.inputs" :key="'input' + index" class="my-1" :md="input.col">
                    <label>{{input.label}}</label>
                    <input class="form-control" :placeholder="input.label" :name="input.name"
                           :id="input.name + 'Filter'" :type="input.type || 'text'">
                </b-col>
                <b-col v-for="(select, index) in filter.selects" :key="'select' + index" class="my-1"
                       :md="select.col">
                    <label >{{select.label}}</label>
                    <select class="form-control form-select" :name="select.name"
                            :id="select.name + 'Filter'">
                        <option v-for="(option, index) in select.options" :key="'option' + index"
                                :value="option.value">
                            {{ option.name }}
                        </option>
                    </select>
                </b-col>
                <slot name="form-filter"></slot>
                <div v-if="Object.keys(filter).length > 0" class="col text-end mt-auto">
                    <router-link v-if="button" :to="url">
                        <button class="btn btn-outline-primary mx-2 my-1" type="button">
                            Adicionar
                        </button>
                    </router-link>
                    <button class="btn btn-danger mx-2 my-1" type="reset" @click="resetTable">
                        Limpar
                    </button>
                    <button type="submit" class="btn btn-primary my-1">Buscar</button>
                </div>
            </div>
        </form>
    </b-card-body>
</template>

<script>

import {setSessions} from "@/components/composables/setSessions";
import {loadTable} from "@/components/composables/spinners";
import {EventBus} from "@/event-bus";

export default {
    props: {
        session: {
            required: true,
            type: String
        },
        filter: {
            required: true,
            type: Object
        },
        button: {
            required: false,
            type: Boolean,
            default: false
        },
        url: {
            required: false,
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loadTable: false,
        };
    },

    methods: {

        // Inserindo valores no filter conforme os valores que estão na localStorage
        getFilter() {
            if (!localStorage.getItem(this.session)) {
                setSessions(this.session);
                setTimeout(() => {
                    this.getFilter();
                }, 300)
            }
            const obj = JSON.parse(localStorage.getItem(this.session))
            const keys = Object.keys(obj.params);
            for (let i = 0; i < keys.length; i++) {
                const input = document.getElementById(keys[i] + 'Filter');
                if (input) input.value = obj.params[keys[i]];
            }
        },

        // Resetando os valores da localSortorage, do filter e fazendo nova busca na store
        resetTable() {
            loadTable()
            setSessions(this.session);
            document.getElementById('filter').reset();
            this.filterTable();
        },

        // Efetuando o filtro da tabela, setando os valores na localStorage e efetuando nova busca na store
        filterTable() {
            loadTable();
            // Pegando a localStorage
            let obj = JSON.parse(localStorage.getItem(this.session));
            // Pegando o array das chaves das variáveis da url
            const keys = Object.keys(obj.params);

            // Selecionando apenas os valores do formulário, os demais permanecem iguais
            for (let i = 0; i < keys.length; i++) {

                if (document.getElementById(keys[i] + 'Filter')) obj.params[keys[i]] = document.getElementById(keys[i] + 'Filter').value;

            }
            // Zerando o index
            obj.params.page = 1;
            // Salvando a localStorage
            localStorage.setItem(this.session, JSON.stringify(obj));
            // this.$refs.paginationComponent.resetValues(this.session);
            this.loadList();
        },

        loadList() {
            console.log('loadList')
            if (!localStorage.getItem(this.session)) setSessions(this.session);
            EventBus.$emit('myEvent');
        },
    },

    mounted() {
        // chamada para inserir dados da localStorage no filtro
        this.getFilter();
    },

};

</script>
