export const filter = {
    inputs: [
        {
            name: 'name',
            label: 'Nome',
            col: '4',
            type: 'text'
        },
    ],
    selects: [
        {
            name: 'mentoring_id',
            label: 'Mentoria',
            col: '4',
            options: [
                {
                    value: '',
                    name: 'Todas mentorias'
                }
            ]
        }
    ]
};
