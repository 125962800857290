export const table = [
    {
        column: '',
    },
    {
        column: 'Nome',
    },
    {
        column: 'Mentoria',
    },
    {
        column: 'Data Inscrição',
    },
    {
        column: 'Valor',
    },
    {
        column: 'Progresso',
    },
];
// 'evaluations'
export const keys = ['img','name', 'title', 'created_at', 'price', 'progress'];
